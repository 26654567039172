.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.product-tips {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 15px;
  color: #fc8142;
  &__icon {
    margin-right: 3px;
  }
  &__goicon {
    margin-left: 3px;
    font-size: 13px;
  }
}
.product-button {
  margin-top: 30%;
  width: 100%;
  height: 55px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.select-product {
  margin-top: 22px;
  overflow-y: auto;
  flex: 1;
  width: 100%;
  padding: 10px 0;
}
.select-product__item {
  width: 92px;
  height: 92px;
  border-radius: 8px;
  background-color: #f4f4f4;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
}
//:not(:nth-child(3n))
.select-product__item {
  margin-right: 6px;
}
.select-product__name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  z-index: 10;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}
.select-product__num {
  width: 17px;
  height: 11px;
  border-radius: 2px;
  // border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 8px;
  margin-left: 2px;
}
.select-product__title {
  flex: 1;
}
.select-product__mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 42px;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  border-radius: 0 0 2px 2px;
  border-radius: 0 0 8px 8px;
}
.select-product__close {
  position: absolute;
  top: -4px;
  right: -2px;
  z-index: 10;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
}
.select-product__close::after {
  content: '';
  position: absolute;
  top: 0px;
  left: -6px;
  right: 0px;
  bottom: -6px;
}
.shop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.shop-header {
  height: 58px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.shop-body {
  flex: 1;
  width: 100%;
  overflow-y: auto;
}
.shop-des {
  height: 76px;
  background-color: #fff;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.footer {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
}
.shop-footer {
  height: 76px;
  background-color: #fff;
  width: 100%;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -3px 12px 1px rgba(0, 0, 0, 0.02);
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 16px 20px;
  box-sizing: border-box;
}
.shop-header__city {
  width: 62px;
  height: 34px;
  border-radius: 4px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #131415;
  font-size: 14px;
  font-weight: 400;
}
.shop-header__city:not(:last-child) {
  margin-right: 8px;
}
.shop-header__city--checked {
  background-color: #fff5ef;
  color: #ff7027;
  font-weight: 500;
}

.shop-footer__close {
  width: 100px;
  height: 44px;
  border-radius: 72px;
  background-color: #f6f6f6;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.shop-footer__close:active {
  opacity: 0.8;
}

.shop-footer__confrim {
  width: 223px;
  height: 44px;
  border-radius: 72px;
  background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop-footer__confrim:active {
  opacity: 0.8;
}
.shop-item {
  width: 100%;
  padding: 12px 16px 14px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.shop-item--checked {
  background-color: #fafafa;
}
.shop-item__content {
  flex: 1;
  overflow: hidden;
  margin-right: 16px;
}
.shop-item__title {
  color: #131415;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  word-break: break-all;
}
.shop-item__address-icon {
  color: #aaaaaa;
  font-size: 12px;
  line-height: 14px;
}
.shop-item__address {
  line-height: 14px;
  color: #aaaaaa;
  font-size: 12px;
  margin-left: 4px;
}
.shop-item__icon--checked {
  font-size: 16px;
  color: #ff7027;
  width: 40px;
  text-align: right;
}
.empty {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-img {
    width: 67px;
    height: 67px;
    object-fit: cover;
  }
  &-title {
    margin-top: 16px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
  }
}
