.popup {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.popupContent {
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.img {
  width: 100%;
  height: 400px;
}
.name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
  box-sizing: border-box;
}
.selectedBox {
  display: flex;
  margin-top: 10px;
  padding: 12px;
  box-sizing: border-box;
}
.selected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  overflow: hidden;
}
.selectedTitle {
  font-size: 14px;
  color: #999;
  margin-right: 4px;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
}
.selectedItem {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
  box-sizing: border-box;
  background-color: color-mix(in srgb, #fc8142 5%, #fff 95%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fc8142;
  cursor: pointer;
  height: 32px;
}
.selectedDel {
  margin-left: 12px;
  font-size: 16px;
  color: #ff0000;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
  }
}
.title {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0 12px;
  box-sizing: border-box;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 14px;
  padding: 0 12px;
  box-sizing: border-box;
}
.colourItem {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
}

.colourItemImg {
  width: 100%;
  height: 90px;
  object-fit: cover;
}
.colourItemLabel {
  font-size: 12px;
  color: #333;
  padding: 4px 12px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
.colourItemSelected {
  border: 1px solid #fc8142;
  color: #fc8142;
  .colourItemLabel {
    color: #fc8142;
  }
}
.selectedNone {
  font-size: 14px;
  color: #666;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
}
.contentItem {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f7f7f7;
}

.contentItemSelected {
  background-color: color-mix(in srgb, #fc8142 5%, #fff 95%);
  color: #fc8142;
}
.footerPl {
  height: 70px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.footer {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.footerBody {
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  height: 60px;
}
.footerBodyClose {
  border: 1px solid #f4f4f4;
  height: 100%;
  width: 70px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 100px;
  &:active {
    opacity: 0.8;
  }
}
.footerBodyAdd {
  border: 1px solid #f4f4f4;
  height: 100%;
  width: 100px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 12px;
  border-radius: 100px;
  &:active {
    opacity: 0.8;
  }
}
.footerBodyConfirm {
  border: 1px solid #fff;
  height: 100%;
  flex: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fc8142;
  border-radius: 100px;
  &:active {
    opacity: 0.8;
  }
}
