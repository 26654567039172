.popup {
  height: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
}
.popup-header {
  height: 43px;
  background-color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.popup-header__close {
  position: absolute;
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 17px;
  box-sizing: border-box;
}
.popup-header__dropdown {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.popup-dropdown__title {
  color: #131415;
  font-size: 19px;
  font-weight: 700;
  margin-right: 8px;
}
.popup-dropdown__icon {
  color: #131415;
  font-size: 13px;
}
.popup-content {
  display: flex;
  flex: 1;
  overflow: hidden;

  .rv-sidebar-item--select::before {
    content: none;
    background-color: transparent;
  }
}
.popup-list {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  :global {
    .rv-popup {
      overflow: hidden;
    }
  }
}

.popup-scroll {
  flex: 1;
  width: 100%;
  overflow: hidden;
}
.popup-body {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
}

.product-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  row-gap: 8px;
}
.product-box__item {
  width: 100%;
  border-radius: 5px;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 13px;
  box-sizing: border-box;
  transition: border 0.1s ease;
}
.product-box__item--checked {
  border: 1px solid #dddddd;
}
.product-box__url {
  border-radius: 2px;
  width: 100%;
  height: 119px;
  overflow: hidden;
}
.product-opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.product-opt__title {
  color: #131415;
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  overflow: hidden;
}

.product-opt__title--checked {
  font-weight: 700;
}

.product-opt__icon {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dbdee1;
  color: #fff;
  font-size: 18px;
  transition: background-color 0.1s ease-in;
}
.product-opt__icon--checked {
  background-color: #131415;
  color: #fff;
}
.popup-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.02);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.popup-footer__body {
  height: 76px;
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popup-body__confrim {
  width: 223px;
  height: 44px;
  background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  &:active {
    opacity: 0.6;
  }

  &--disabled {
    opacity: 0.6;
  }
  &--no-use {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
  }
}
.popup-body__close {
  width: 100px;
  height: 44px;
  border-radius: 24px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
}
.popup-des {
  height: 76px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.empty {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-img {
    width: 67px;
    height: 67px;
    object-fit: cover;
  }
  &-title {
    margin-top: 16px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
  }
}
