.dropdown {
  :global {
    .rv-dropdown-menu__item {
      padding: 0 12px;
      flex: none;
    }

    .rv-dropdown-item__content.rv-popup {
      border-radius: 0 0 16px 16px;
    }
    .rv-dropdown-menu__bar {
      box-shadow: none;
    }
    .rv-dropdown-item {
      left: var(--rv-sidebar-width);
    }
  }
}
.dropdown-scroll {
  width: 100%;
  height: 138px;
  overflow-y: auto;
  padding: 12px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content;
  row-gap: 10px;
  column-gap: 10px;
  &__item {
    width: 100%;
    height: 32px;
    border: 1px solid #f8f8f8;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #131415;
    font-size: 13px;
    border-radius: 4px;
    font-weight: 400;
  }
  &__item--checked {
    width: 100%;
    height: 32px;
    border: 1px solid #fc8142;
    background-color: #fff5ef;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff7027;
    font-size: 13px;
    border-radius: 4px;
    font-weight: 400;
  }
}
.dropdown-button {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  border-radius: 0 16px 16px 0;
  box-sizing: border-box;
  &__reset {
    width: 98px;
    height: 34px;
    border-radius: 23px;
    border: 1px solid #c1c1c8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
  }
  &__confirm {
    width: 155px;
    height: 36px;
    background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
}
