.issue-item {
  margin: 0 40px 0px 40px;
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  &__count {
    line-height: 1;
  }
  &__index {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
  }
  &__length {
    color: rgba(0, 0, 0, 0.3);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 11px;
  }
  &__title {
    color: #201d1b;
    font-size: 20px;
    font-weight: 500;
    white-space: pre-wrap;
    word-break: break-word;
    margin-top: 4px;
  }
  &__subtitle {
    color: #a2a2a2;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
  }
  &__scroll {
    width: 100%;
    margin-bottom: 28px;
    flex: 1;
    overflow: hidden;
  }
}
.issue-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 180px;
  &__pre {
    background-color: rgba(255, 255, 255, 1);
    width: 80px;
    height: 50px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #707070;
    font-size: 16px;
    font-weight: 400;
    &--disabled {
      color: rgba(112, 112, 112, 0.12);
      background-color: rgba(255, 255, 255, 1);
      width: 80px;
      height: 50px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__next {
    background-color: rgba(255, 255, 255, 1);
    width: 80px;
    height: 50px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #707070;
    font-size: 16px;
    font-weight: 400;
    &--disabled {
      background-color: rgba(255, 255, 255, 1);
      width: 80px;
      height: 50px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 16px;
      font-weight: 400;
      color: rgba(145, 119, 119, 0.12);
    }
  }
}
