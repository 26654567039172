.popup-item {
  width: 100%;
  box-sizing: border-box;
  // display: flex;
  margin-bottom: 20px;
  padding-right: 20px;
}

.popup-img {
  width: 100%;
  height: 178px;
  line-height: 0;
  position: relative;
}

.popup-img__icon {
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 3;
  background-color: rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(241, 241, 241, 0.9);
  font-size: 16px;
}

.popup-item__img {
  // width: 100px;
  // height: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.popup-item__box {
  // margin-left: 12px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup-item__box__big {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}

.popup-item__title {
  color: #201d1b;
  font-size: 15px;
  font-weight: 500;
}

.popup-item__tags {
  color: #656592;
  font-weight: 400;
  margin-top: 9px;

  & > div {
    background: #ededf6;
    border-radius: 3px 3px 3px 3px;
    line-height: 1;
    height: 17px;
    padding: 0 4px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

.popup-item__link {
  width: 120px;
  // height: 24px;
  // border-radius: 24px;
  // border: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
}

.popup-item__select {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #d4d6d8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numbertext {
  color: #fc8142;
  font-size: 16px;
  & > span {
    color: #666;
    font-size: 14px;
    padding: 0 4px;
    box-sizing: border-box;
  }
}
