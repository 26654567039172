.not-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.not-login__logo {
  width: 160px;
  height: 80px;
  margin: 0 auto;
  display: block;
}
.not-login__text {
  color: #394150;
  font-size: 16px;
  margin: 80px auto 23px auto;
  text-align: center;
}
.not-login__in {
  width: 295px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  &:active {
    filter: contrast(90%);
  }
}
