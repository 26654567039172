.upload {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
:global {
  .rv-uploader {
    display: none;
  }
}
.upload-tips {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 15px;
  color: #fc8142;
  &__icon {
    margin-right: 3px;
  }
}
.upload-button {
  margin-top: 30%;
  width: 100%;
  height: 55px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.select-upload {
  margin-top: 22px;
  overflow-y: auto;
  flex: 1;
  width: 100%;
  padding: 10px 0;
}
.select-upload__item {
  width: 92px;
  height: 92px;
  border-radius: 8px;
  background-color: #f4f4f4;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
}
//:not(:nth-child(3n))
.select-upload__item {
  margin-right: 6px;
}
.select-upload__name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  z-index: 10;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
}
.select-upload__num {
  width: 17px;
  height: 11px;
  border-radius: 2px;
  // border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 8px;
  margin-left: 2px;
}
.select-upload__title {
  flex: 1;
}
.select-upload__mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 42px;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  border-radius: 0 0 2px 2px;
  border-radius: 0 0 8px 8px;
}
.select-upload__close {
  position: absolute;
  top: -4px;
  right: -2px;
  z-index: 10;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
}
.select-upload__close::after {
  content: '';
  position: absolute;
  top: 0px;
  left: -6px;
  right: 0px;
  bottom: -6px;
}
