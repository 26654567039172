.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.product-tips {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 15px;
  color: #fc8142;
  &__icon {
    margin-right: 3px;
  }
}
.product-button {
  margin-top: 30%;
  width: 100%;
  height: 55px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 15px;
  font-weight: 400;
}
.select-relatives {
  margin-top: 22px;
  overflow-y: auto;
  flex: 1;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  &__item {
    width: 100%;
    height: 64px;
    border-radius: 10px;
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  &__relation {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
  }
  &__content {
    flex: 1;
    overflow: hidden;
    height: 40px;
    margin-right: 8px;
  }
  &__name {
    color: #131415;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
  }
  &__box {
    margin-top: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    display: -webkit-box;			
    -webkit-line-clamp: 1;					
    -webkit-box-orient: vertical;
  }
  &__subtitle {
    color: #838391;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.6;
    &::after {
      content: '';
      width: 1px;
      height: 16px;
      background-color: rgba(51, 51, 51, 1);
      transform: scale(0.5) translateY(40%);
      display: inline-block;
      margin: 0 6px;
    }
    &:last-of-type {
      &::after {
        width: 0;
        margin: 0;
      }
    }
  }
  &__edit {
    color: #aaaaaa;
    font-size: 14px;
    position: relative;
    &::after {
      position: absolute;
      top: -17px;
      left: -9px;
      right: -9px;
      bottom: -17px;
    }
  }
  &__del {
    color: #aaaaaa;
    font-size: 16px;
    position: relative;
    margin-left: 21px;
    &::after {
      position: absolute;
      top: -16px;
      left: -8px;
      right: -8px;
      bottom: -16px;
    }
  }
}
