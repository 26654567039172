.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fff3e6 0%, #fffcfc 78%, #fffefe 100%);
  position: relative;
  z-index: 2;
  padding-top: 32px;
  box-sizing: border-box;
}
.bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 375px;
  z-index: -1;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.02);
  &-body {
    width: 100%;
    height: 80px;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &__button {
      user-select: none;
      width: 100%;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
      font-size: 18px;
      font-weight: 700;
      &:active {
        filter: opacity(0.9);
      }
    }
  }
  &-des {
    height: 80px;
  }
}
.popup {
  width: 100%;
  height: 100%;
  padding: 0 20px 16px 20px;
  box-sizing: border-box;
  overflow: hidden;
  &-content {
    margin: 38px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &__title {
      color: #201d1b;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      user-select: none;
      margin-left: 9px;
    }
  }
  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__cancel {
      user-select: none;
      width: 161px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      background-color: #f6f6f6;
      font-size: 16px;
      font-weight: 400;
      border-radius: 24px;
      &:active {
        opacity: 0.9;
      }
    }
    &__submit {
      user-select: none;
      background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
      width: 161px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      border-radius: 24px;
      &:active {
        opacity: 0.9;
      }
    }
  }
}

.success {
  border-radius: 20px;

  background: linear-gradient(180deg, #ffeedf 0%, #ffffff 72%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 151px;
  position: relative;
}
.success__title {
  color: #201d1b;
  font-size: 18px;
  margin-top: 78px;
  font-weight: 500;
  line-height: 1;
}
.success__subtitle {
  color: #a2a2a2;
  font-size: 13px;
  margin-top: 12px;
  font-weight: 400;
  line-height: 1;
}
.success__img {
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
}
