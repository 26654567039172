.t-date-selector {
  background-color: transparent;

  ::v-deep .rv-picker {
    background-color: transparent !important;
  }
  [class*='rv-picker__mask'] {
    background-image: none !important;
    display: none;
  }

  [class*='rv-hairline']::after {
    border-color: #fc8142;
  }
}
