.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #fff3e6 0%, #fffcfc 68%, #fffefe 100%);
  position: relative;
}
.title {
  width: 187px;
  height: 82px;
  object-fit: cover;
}
.tips {
  color: rgba(42, 21, 7, 0.6);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 14px;
  padding: 4px 6px;
  box-sizing: border-box;
  line-height: 1;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 4px;
}

.content {
  color: #503f35;
  font-size: 15px;
  font-weight: 400;
  margin-top: 28px;
  margin-bottom: 46px;
  line-height: 27px;
}

.submit {
  width: 307px;
  height: 52px;

  background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin: 31px auto 0 auto;
}
.submit:active {
  filter: contrast(80%);
}

.footer-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 320px;
}
