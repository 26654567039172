.t-selector {
  height: 100%;
  overflow-y: auto;
  &__item {
    height: 44px;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 15px;
    font-weight: 400;
    background-color: #fff;
    margin-bottom: 12px;
    border-radius: 22px;
    &--checked {
      background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
      color: #ffffff;
    }
  }
  &__icon {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #f2f6fc;
    background-color: transparent;
  }
  &__icon--round {
    width: 14px;
    height: 14px;
    border-radius: 50% !important;
    transform: translateY(2px);
    border: 1px solid #f2f6fc;
    background-color: transparent;
  }
  &__icon--checked {
    font-size: 14px;
    color: #fff;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #fff;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.button {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 1;
}
.button__text {
  color: #838391;
  font-size: 14px;
  font-weight: 400;
}
.button__icon {
  font-size: 13px;
  color: #838391;
  margin-left: 3px;
}

.t-selector::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}
